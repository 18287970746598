import React from 'react'
import { Content as TitleContent } from './Header'
import { Content as Entries } from 'components/shared/CategoryNameList'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const Contents = ({ children }) => (
  <div className={styles.Contents}>{children}</div>
)

/**
 *
 */
const Body = () => (
  <div className={styles.Body}>
    <Entries />
  </div>
)

/**
 *
 */
const Header = () => (
  <div className={styles.Header}>
    <TitleContent />
  </div>
)

/**
 *
 */
export const Content = () => {
  return (
    <Container>
      <Contents>
        <Header />
        <Body />
      </Contents>
    </Container>
  )
}
