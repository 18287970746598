import React from 'react'
import { Context } from './Context'

/**
 *
 */
export const withCampaign = (Component) => (props) => {
  const { campaign } = React.useContext(Context)
  return <Component campaign={campaign} {...props} />
}
