import React from 'react'
import { Context } from './Context'
import { Provider as ReferenceProvider } from './Reference'

/**
 *
 */

export const Each = ({ start_offset, end_offset, postType, children }) => {
  const { products } = React.useContext(Context)

  const sliceData = (start_offset, end_offset, data) => {
    if (start_offset || end_offset) {
      return data.slice(start_offset, end_offset)
    } else {
      return data
    }
  }

  const postData = sliceData(start_offset, end_offset, products)

  return postData.map((product) => (
    <ReferenceProvider key={product.id} product={product}>
      {children}
    </ReferenceProvider>
  ))
}
