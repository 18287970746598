import React from 'react'
import { withCampaign } from '../withCampaign'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { DefaultHTMLRenderer } from 'components/shared/Renderer/Content'
import { HTMLRenderer as ExcerptRenderer } from 'components/shared/Excerpt'
import { DefaultImageRenderer as ImageRenderer } from 'components/shared/Renderer'
import { DefaultTitleRenderer as TitleRenderer } from 'components/shared/Renderer'

/**
 *
 */
export const Title = withCampaign(
  ({ campaign, render: Renderer = TitleRenderer, ...props }) => {
    if (campaign === undefined) {
      return null
    }
    return <Renderer value={campaign.title} {...props}></Renderer>
  }
)

/**
 *
 */
export const Content = withCampaign(
  ({ campaign, render: Renderer = DefaultHTMLRenderer, ...props }) => {
    if (campaign === undefined) {
      return null
    }
    return <Renderer value={campaign.content} {...props}></Renderer>
  }
)

/**
 *
 */
export const Excerpt = withCampaign(
  ({ campaign, render: Renderer = ExcerptRenderer, ...props }) => {
    if (campaign === undefined) {
      return null
    }
    return <Renderer value={campaign.excerpt} {...props}></Renderer>
  }
)

/**
 *
 */
export const Image = withCampaign(
  ({ campaign, render: Renderer = ImageRenderer, ...props }) => {
    if (campaign === undefined) {
      return null
    }
    return campaign.featured_media ? (
      <Renderer value={campaign.featured_media} alt="thumbnail" {...props} />
    ) : null
  }
)
