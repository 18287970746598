import React from 'react'
import * as styles from './styles.module.scss'
import * as PageTitle from 'components/PageTitle'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Title = () => (
  <div className={styles.Title}>
    <PageTitle.Title />
  </div>
)

/**
 *
 */
const Description = () => (
  <div className={styles.Description}>
    <PageTitle.Text />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Title />
    <Description />
  </Container>
)
