import React from 'react'
import { graphql } from 'gatsby'
import * as Categories from 'modules/category/components'
import * as Products from 'modules/product/components'
import * as Campaign from 'modules/campaign'
import * as Location from 'modules/location/components'
import * as PageNumber from 'modules/pageNumber/components'
import * as Width from 'modules/page/width/components/Reference'
import { Content } from 'partials/CategoriesPage'
import * as PageTitle from 'components/PageTitle'

/**
 *
 */
const CategoryTemplate = ({ data, location, pageContext }) => {
  const postLength = data.length.nodes.length
  const { limit, skip, currentPage } = pageContext
  const offsetNum = currentPage * limit

  const numPages = Math.ceil(postLength / limit)

  //GraphQlではカスタムフィールドに入力した値をintにしてくれなかったのでここでintに変換
  const nodes = data.products.nodes.map((e) => {
    const priority = Number(e.acf.priority)

    const newE = { ...e, acf: { priority: priority } }

    return newE
  })

  //カスタムフィールドに入力された数値を比較して降順にデータを並び替える
  const priorityArray = nodes.sort((a, b) => {
    return b.acf.priority - a.acf.priority
  })

  const sortArray = priorityArray.slice(skip, offsetNum)

  const {
    name = '選択したカテゴリ名',
    description = '',
    categoryNodes = [],
  } = pageContext
  return (
    <Width.Initializer>
      <PageTitle.Initializer title={name} text={description}>
        <Categories.Provider value={{ categories: categoryNodes }}>
          <Products.Provider value={{ products: sortArray }}>
            <Campaign.Provider value={{ campaigns: data.campaigns.nodes }}>
              <Location.Provider value={location}>
                <PageNumber.Provider value={numPages}>
                  <Content />
                </PageNumber.Provider>
              </Location.Provider>
            </Campaign.Provider>
          </Products.Provider>
        </Categories.Provider>
      </PageTitle.Initializer>
    </Width.Initializer>
  )
}

/**
 *
 */
export const query = graphql`
  query CategoryTemplateQuery(
    $slug: String!
    $skip: Int!
    $limit: Int!
    $postStatus: String!
  ) {
    categories: allWordpressCategory(
      filter: { parent_element: { slug: { eq: $slug } } }
    ) {
      nodes {
        id
        name
        slug
      }
    }
    products: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: $slug } } }
        status: { glob: $postStatus }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        content
        wordpress_id
        date(formatString: "YYYY-MM-DD")
        excerpt
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400, height: 300)
            }
          }
        }
        acf {
          priority
        }
      }
    }
    campaigns: allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: "campaign" } } }
        status: { glob: $postStatus }
      }
    ) {
      nodes {
        title
        excerpt
        wordpress_id
        content
        featured_media {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000, height: 700)
            }
          }
        }
      }
    }
    length: allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        title
        wordpress_id
      }
    }
  }
`

/**
 *
 */
export default CategoryTemplate
